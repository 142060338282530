<template>
    <div class="page-container">
        <div class="page-top">
            <div class="pic-box">
                <img src="../../assets/productdetail/tmp-cover-pic1.png" alt="" class="nft-pic">
            </div>
            <div class="info-box">
                <div class="info-title-box">
                    <div class="info-title">「Esprimere 表达」欢腾鱼-青：自由之约</div>
                    <div class="btn-grp">
                        <div class="btn-fav">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav">
                            112
                        </div>
                        <img src="../../assets/productdetail/icon-share.png" alt="" class="icon-share">
                    </div>
                </div>
                <div class="fxl-buycount">
                    <img src="../../assets/productdetail/icon-fxl.png" alt="" class="icon-fxl">
                    <div class="fxl-count">300</div>
                    <div class="buy-count">已有 <span>56</span>人购买</div>
                </div>
                <div class="auth-info">
                    艺术家：墨菲链 | 作品集：墨霏与红酒红酒
                </div>
                <div class="price">¥ 20</div>
                <div class="btn-buy">立即购买</div>
                <div class="chain-info">
                    <div class="chain-head">链上信息</div>
                    <div class="chain-block">
                        <div class="chain-info-item">合约地址：0x84FC3ea417Eb7cB229C6ce95843f68dAC59C0088</div>
                        <div class="chain-info-item">合约类型：ERC-1155</div>
                        <div class="chain-info-item">区块链：文昌链</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <!-- 作者信息 -->
            <div class="auth-block">
                <div class="pic-name">
                    <img src="../../assets/productdetail/icon-author1.png" alt="" class="auth-pic">
                    <div class="auth-name">墨霏链</div>
                </div>
                <div class="auth-detail">墨霏代表作《屌丝男士》，《男模的秘密》资深传媒人十余年文娱产业经验，原CCTV6,光线传媒签约主持人演员，吴京、关晓彤、柳岩、谢楠、包贝尔同门，与国内外一线明星长期保持互动合作关系，影视资源丰富。出品腾讯大电影《男模的秘密》袁姗姗、宁静等众多明星鼎力支持，参演与参与影视项目《屌丝男士》，《大话西游3》，《分手大师》，《画壁》，《匹夫》，《全城戒备》，《精武风云陈真》，《最爱》等。全平台“墨霏</div>
            </div>
            <div class="page-title">
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
                <div>藏品描述</div>
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
            </div>
            <div class="desc-list">
                <div class="desc-item">
                    <div class="desc-item-head">藏品背景</div>
                    <div class="desc-item-content">“表达”是来自意大利的百年酒庄可溯源的艺术红酒，艺术和红酒的完美结合。 葡萄酒本身就是一门艺术，酿酒师以葡萄为“颜料”，以酿造为表现手法，最终呈现了一瓶瓶美妙的葡萄酒作品，酒标就是那画龙点睛的一笔。两种艺术的结合给你带来视觉和味觉上的双重享受。造个人商业IP。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">藏品介绍</div>
                    <div class="desc-item-content">“表达”红酒欢腾鱼系列青标“自由之约”。 我的鱼，它知道我的密码，已解开很多人的桎梏，让他们能像我一样与你自由对话，我遵守与你的约定，不管那些陈规，让表达出现在每处有呼唤的地方。该系列总计六款，发行数量递增，第一期：150份；第二期：160份；第三期：160份；第四期：170份；第五期：170份；第
六期：10份。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head blue">权益赋能</div>
                    <div class="desc-item-content blue">合成通道开放后，集齐六款不同《欢腾鱼系列》酒标的藏家，将可合成欢腾鱼系列红酒一瓶。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">权益说明</div>
                    <div class="desc-item-content">数字藏品可以通俗理解为区块链凭证。数字藏品为虚拟数字商品，特性为不可分割、不可替代、独一无二。数字藏品常见于文化艺术品领域、知识产权的链上发行、流
转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区块链技术的一类场景应用。</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>